import ky, {
  AfterResponseHook,
  BeforeRequestHook,
  HTTPError,
  NormalizedOptions,
} from 'ky'
import { KyOptions } from 'ky/distribution/types/options'
import { z } from 'zod'
import { auth } from '~/lib/auth'
import { vars } from '~/lib/env'
import { q } from '~/lib/query'
import { tryto } from '~/lib/utils/tryto'

interface RefreshTokenOptions {
  disallowRefresh?: boolean
}

declare module 'ky' {
  interface Options extends RefreshTokenOptions {}
  interface NormalizedOptions extends RefreshTokenOptions {}
}

const authHook: BeforeRequestHook = (req, _opts) => {
  const token = auth.getToken()
  if (token) {
    req.headers.set('Authorization', token)
  }
}

const refreshTokenHook: AfterResponseHook = async (req, options, res) => {
  if (res.status === 401 && !options.disallowRefresh) {
    const refreshToken = auth.getRefresh()
    if (refreshToken) {
      const res = await fetchRefreshToken(refreshToken)
      if (res) {
        const { accessToken, refreshToken } = res.payload

        auth.setToken(accessToken)
        auth.setRefresh(refreshToken)

        console.debug('💧 token refreshed')

        /* retry with new token and disallow refresh attempts  */
        const retryOptions = { ...options } as import('ky').Options
        retryOptions.disallowRefresh = true

        return http(req, retryOptions)
      }
    }
    auth.clearAll()
  }
}

export class UnpackedHTTPError extends HTTPError {
  unpacked: q.UnpackedHttpError

  constructor(
    unpacked: q.UnpackedHttpError,
    original: {
      response: Response
      request: Request
      options: NormalizedOptions
    },
  ) {
    super(original.response, original.request, original.options)
    this.unpacked = unpacked
  }
}

export function isUnpackedError(value: unknown): value is UnpackedHTTPError {
  return value instanceof UnpackedHTTPError
}

const base = ky.create({
  prefixUrl: vars.VITE_API_BASE_URL,
  hooks: {
    beforeError: [
      async (error) => {
        const unpacked = await q.unpackHttpError(error)
        if (process.env.NODE_ENV === 'development') {
          console.log('HTTPError Details →', unpacked)
        }
        const unpackedError = new UnpackedHTTPError(unpacked, error)
        return unpackedError
      },
    ],
  },
})

/** HTTP client without Authorization */
export const httpGuest = base

/** HTTP client for SSO without Authorization (for /public routes) */
export const httpSsoGuest = base.extend({
  prefixUrl: vars.VITE_SSO_BASE_URL,
})

/** Main HTTP client with Authorization */
export const http = base.extend({
  hooks: {
    beforeRequest: [authHook],
    afterResponse: [refreshTokenHook],
  },
})

/** Main Userway HTTP client */
export const apiUserwayBase = http.extend({
  prefixUrl: vars.VITE_API_USERWAY_BASE_URL,
})

/** HTTP client for SSO with Authorization */
export const httpSso = http.extend({
  prefixUrl: vars.VITE_SSO_BASE_URL,
})
export const httpApiSso = http.extend({
  prefixUrl: vars.VITE_API_SSO_BASE_URL,
})

export const messageResponseSchema = q.responseSchema(
  z.object({
    msg: z.string(),
    aux: z.string().nullish(),
  }),
)

const refreshTokenResponseSchema = q.responseSchema(
  z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
  }),
)

type RefreshTokenResponse = z.infer<typeof refreshTokenResponseSchema>

let inflightRefresh: Promise<RefreshTokenResponse> | null = null
export async function fetchRefreshToken(token: string) {
  if (inflightRefresh) {
    const [res] = await tryto.promise(inflightRefresh)
    return res || null
  } else {
    inflightRefresh = httpGuest
      .get('refresh-token', { headers: { Authorization: token } })
      .then(async (res) => {
        const json = await res.json()
        return refreshTokenResponseSchema.parse(json)
      })

    const [res] = await tryto.promise(inflightRefresh)

    inflightRefresh = null

    return res || null
  }
}
