import { tailspin } from 'ldrs'

tailspin.register()

export function TailspinSpinner(props: JSX.IntrinsicElements['l-tailspin']) {
  return (
    <l-tailspin
      size="16"
      stroke="2"
      speed="1"
      color="currentColor"
      {...props}
    ></l-tailspin>
  )
}
